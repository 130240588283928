import {Component, createMemo, createSignal, onCleanup, useContext} from "solid-js";
import {tonAppState} from "../../../config/ton-app.state";
import {ActionConfiguration, LoadableReady, WalletsModalCloseReason} from "../../../../models";
import {ConnectAdditionalRequest} from "@okxconnect/tonsdk";
import {SingleWalletModal} from "../../../../app/views/modals/wallets-modal/single-wallet-modal";
import {ConnectorContext} from "../../../../config/connector.context";
import {OKXConnectUiContext} from "../../../../config/okx-connect-ui.context";
import {defaultOpenTonUniversalLink, showTonTgWallet} from "../../../../app/utils/wallets";
import {logDebug,EngineConnectInfo} from "@okxconnect/core";
import { 
  getTonSingleWalletModalIsOpened, 
  getTonSingleWalletModalWalletInfo, 
  lastTonSelectedWalletInfo, 
  setLastTonSelectedWalletInfo 
} from "../../../config/ton-modals-state";
import { OKXTonConnectUI } from "../../../../library";

export const TonSingleWalletModal: Component = () => {
  const tonConnectUI = useContext(OKXConnectUiContext)! as OKXTonConnectUI;
  const connector = useContext(ConnectorContext)!;
  const [connectError, setConnectError] = createSignal<boolean>(false);
  const [sessionConnecting, setSessionConnecting] = createSignal<boolean>(false);
  const additionalRequestLoading = (): boolean =>
      tonAppState.connectRequestParameters?.state === "loading";

  const [universalLinkParams, setUniversalLinkParams] = createSignal<EngineConnectInfo | null>(null);

  window.addEventListener("okx-ton-connect-connection-info-before-jump", (event) => {
    const customEvent = event as CustomEvent;
    logDebug("TON   :::",JSON.stringify(customEvent.detail))
    if (customEvent.detail && "connectInfo" in customEvent.detail){
      setUniversalLinkParams(customEvent.detail.connectInfo)
    }
  });

  const additionalRequest = createMemo(() => {
    if (additionalRequestLoading()) {
      return undefined;
    }
    return (
      tonAppState.connectRequestParameters as LoadableReady<ConnectAdditionalRequest>
    )?.value;
  });

  const onClose = (closeReason: WalletsModalCloseReason): void => {
    tonConnectUI.closeModal(closeReason);
  };

  const unsubscribe = connector.onStatusChange((wallet) => {
    if (wallet) {
      onClose("wallet-selected");
    }
  },() =>{
    setConnectError(true)
  });
  onCleanup(unsubscribe);

  return (
    <SingleWalletModal
      opened={getTonSingleWalletModalIsOpened()}
      walletInfo={getTonSingleWalletModalWalletInfo()}
      loadingBeforeConnect={() => {
        return tonAppState.connectRequestParameters?.state === "loading";
      }}
      connect={() => {
        return new Promise<string>((resolve, reject) => {
          setUniversalLinkParams(null)
          setConnectError(false)
          tonConnectUI.handleConnect(async (actionConfiguration: ActionConfiguration | undefined) => {
            return await connector.connect({
              openUniversalLink: defaultOpenTonUniversalLink(), // must be same as isSingleWallet
              tonProof: additionalRequest()?.tonProof,
              redirect: actionConfiguration?.returnStrategy,
              tmaReturnUrl: actionConfiguration?.tmaReturnUrl
            })
          }).then((url)=>{
            resolve(url);
          }).catch((error)=>{
            onClose("action-cancelled");
            logDebug('TonSingleWalletModal connect error', JSON.stringify(error))
            reject(error);
          });
        })
      }}
      onClose={onClose}
      universalLinkParams={universalLinkParams}
      isSingleWallet={!showTonTgWallet()} // must be same as openUniversalLink
      lastSelectedWalletInfo={lastTonSelectedWalletInfo()}
      setLastSelectedWalletInfo={setLastTonSelectedWalletInfo}
      language={tonAppState.language}
      connectError ={connectError}
      showRetry ={true}
      sessionConnecting={sessionConnecting}
    />
  );
};
