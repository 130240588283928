import {createStore} from 'solid-js/store';
import {PartialColorsSet, THEME} from '../../models';
import {defaultDarkColorsSet, defaultLightColorsSet} from '../../app/styles/default-colors';
import {DefaultTheme} from "solid-styled-components";

export const [universalThemeState, setUniversalThemeState] = createStore<DefaultTheme>({
    theme: THEME.LIGHT,
    colors: defaultLightColorsSet,
    borderRadius: 'm'
});

const themeColorsMappingDefault = {
    [THEME.LIGHT]: defaultLightColorsSet,
    [THEME.DARK]: defaultDarkColorsSet
};

const themeCustomColors: Record<THEME, PartialColorsSet | undefined> = {
    [THEME.LIGHT]: undefined,
    [THEME.DARK]: undefined
};

// export function setUniversalTheme(theme: THEME, colorsSet?: Partial<Record<THEME, PartialColorsSet>>): void {
export function setUniversalTheme(theme: THEME): void {
    // if (colorsSet) {
    //     themeCustomColors[THEME.DARK] = mergeOptions(
    //         colorsSet[THEME.DARK],
    //         themeCustomColors[THEME.DARK]
    //     );
    //     themeCustomColors[THEME.LIGHT] = mergeOptions(
    //         colorsSet[THEME.LIGHT],
    //         themeCustomColors[THEME.LIGHT]
    //     );
    // }

    setUniversalThemeState({
        theme,
        // colors: mergeOptions<ColorsSet>(themeCustomColors[theme], themeColorsMappingDefault[theme])
        colors: themeColorsMappingDefault[theme]
    });
}

// export function setBorderRadius(borderRadius: BorderRadius): void {
//     setUniversalThemeState({borderRadius});
// }

// export function setColors(colorsSet: Partial<Record<THEME, PartialColorsSet>>): void {
//     themeCustomColors[THEME.DARK] = mergeOptions(
//         colorsSet[THEME.DARK],
//         themeCustomColors[THEME.DARK]
//     );
//     themeCustomColors[THEME.LIGHT] = mergeOptions(
//         colorsSet[THEME.LIGHT],
//         themeCustomColors[THEME.LIGHT]
//     );
//
//     setUniversalThemeState(state => ({
//         colors: mergeOptions<ColorsSet>(
//             themeCustomColors[state.theme as THEME],
//             themeColorsMappingDefault[state.theme as THEME]
//         )
//     }));
// }
