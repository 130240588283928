import { styled } from "solid-styled-components";
import { IconButton } from "../../../../components";

export const MobileConnectionModalStyled = styled.div``;

export const BodyStyled = styled.div<{ isDeclined: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${(props) => (props.isDeclined ? '200px' : '325px')};;
  gap: 24px;
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 12px;
  left: 16px;
`;
