import {
  encodeConnectParams,
  encodeTWAConnectURLParams,
  EngineConnectInfo,
  getAppWalletDeepLink, getAppWalletShortDeepLink, getOKXLink,
  getTelegramWalletTWAUrl,
  getUniversalLink,
  logDebug,
  openOKXTMAWalletlinkWithFallback, useShortLink,
  reportEvent,
   OKXReportType,
   Report_Proceed_Proceed_View,
   Report_Proceed_QRCode_View,
   Report_Loading_ConnectWallet_View,
   Report_ChooseType_ConnectWallet_View,
   Report_Proceed_Retry_Click,
   Report_Proceed_QRCode_Click,
   Report_ChooseType_ConnectWallet_Click,
   Report_WalletType_Key,
  WalletInfo
} from "@okxconnect/core";
import {Component, createEffect, createMemo, createSignal, JSX, onCleanup, onMount, Show, useContext,} from "solid-js";
import {BodyStyled, MobileConnectionModalStyled} from "./style";
import {useTheme} from "solid-styled-components";
import {MobileConnectionQR} from "../mobile-connection-modal/mobile-connection-qr";
import {ConnectorContext} from "../../../../../config/connector.context";
import ConnectAppTgModalPage from "../layout/ConnectAppTgModalPage";
import LoadingModalPage from "../layout/LoadingModalPage";
import {H1Styled} from "../style";
import ConnectionDeclinedModalPage from "../layout/ConnectionDeclinedModalPage";
import ModalFooter from "../layout/ModalFooter";
import {isInTelegramBrowser, isInTMA} from "../../../../utils/tma-api";
import {browserDebug} from "../../../../utils/wallets";
import {OKXConnectUiContext} from "../../../../../config/okx-connect-ui.context";
import { WalletInfoRemoteWithOpenMethod, WalletInfoWithOpenMethod, WalletOpenMethod } from "../../../../../models";

export interface DesktopConnectionProps {
  wallet: WalletInfo;
  onBackClick: () => void;
  backDisabled?: boolean;
  connect: () => Promise<unknown>;
  isSingleWallet: boolean;
  universalLinkParams: () => EngineConnectInfo | null;
  showRetry:boolean;
  connectError:() => boolean
  sessionConnecting:() => boolean
  lastSelectedWalletInfo: WalletInfoRemoteWithOpenMethod | {
    openMethod: WalletOpenMethod;
  } | null;
  setLastSelectedWalletInfo(walletInfo: WalletInfoWithOpenMethod | {
    openMethod: WalletOpenMethod;
} | null): void;
}

export const DesktopConnectionModal: Component<DesktopConnectionProps> = (
  props
) => {
  const theme = useTheme();
  const [firstClick, setFirstClick] = createSignal(true);
  const [showQR, setShowQR] = createSignal(false);
  const [walletType, setWalletType] = createSignal<"app" | "mini" | null>(null);
  const showAppAndTgState = createMemo(
    () => !props.isSingleWallet && walletType() === null
  );
  const [miniUniversalLink, setMiniUniversalLink] = createSignal<string | null>(
    null
  );
  const okxConnectUI = useContext(OKXConnectUiContext)

  var lastActionBtnShowState: boolean | undefined;
  var lastQRViewShowState: boolean | undefined;
  let showLoading = () => props.universalLinkParams() === null && !props.connectError();
  var lastLoadingState: boolean | undefined;

  createEffect(() => {
    logDebug("TON customEvent.detail  :::",createEffect)
    var info = props.universalLinkParams();
    logDebug("TON customEvent.detail  :::",JSON.stringify(info))
    const showState = showAppAndTgState();
    const showLoadingState = showLoading();
    if (showState != lastActionBtnShowState && !showLoadingState) {
      if (showState) {
        // report show
        reportEvent(OKXReportType.PAGE_IN, Report_ChooseType_ConnectWallet_View, '')
        reportEvent(OKXReportType.PAGE_OUT, Report_Proceed_QRCode_View, '')
      } else {
        // report hidden
        reportEvent(OKXReportType.PAGE_OUT, Report_ChooseType_ConnectWallet_View, '')
        reportEvent(OKXReportType.PAGE_IN, Report_Proceed_QRCode_View, '')
      }
      lastActionBtnShowState = showState;
    }

    if (showLoadingState != lastLoadingState) {
      if (showLoadingState) {
        // report show
        reportEvent(OKXReportType.PAGE_IN, Report_Loading_ConnectWallet_View, '')
      } else {
        // report hidden
        reportEvent(OKXReportType.PAGE_OUT, Report_Loading_ConnectWallet_View, '')
      }
      lastLoadingState = showLoadingState;
    }
    if (info) {
      props.setLastSelectedWalletInfo({
        ...props.wallet,
        openMethod: "universal-link",
      });

      var connectRequest = info.connectRequest
      var tonConnectRequest = info.tonConnectRequest

      if (!tonConnectRequest){
        tonConnectRequest = connectRequest
      }

      if (connectRequest) {
        var qrLink = ""

        if (useShortLink()){
          qrLink = getOKXLink(connectRequest)
        }else{
          var encodeParams = encodeConnectParams({
            ...tonConnectRequest,
            redirect: "none"
          })
          qrLink = getUniversalLink(getAppWalletDeepLink(encodeParams), true)
        }
        setQrLink(qrLink);

        var encodeTGParams = encodeTWAConnectURLParams(connectRequest)
        var tgUrl = getTelegramWalletTWAUrl(encodeTGParams, info.tmaReturnUrl ?? "back")
        setMiniUniversalLink(tgUrl)

        logDebug(`DesktopConnectionModal  setUniversalLink  tgUrl  ${tgUrl}    qrLink ${qrLink}`)
      }
    }
    if (props.connectError()){
      setShowQR(false)
    }
  });

  const [qrLink, setQrLink] = createSignal<string | null>(null);

  onMount(async () => {
    try {
       await props.connect().catch((error) => 
        logDebug("DesktopConnectionModal connect catch error ",JSON.stringify(error))
      );
    } catch (error) {
      logDebug("DesktopConnectionModal connect  error ",JSON.stringify(error));
    }
  });

  const onRetry = (): void => {
    setWalletType(null);
    const forceRedirect = !firstClick();
    setFirstClick(false);
    reportEvent(OKXReportType.CLICK, Report_Proceed_Retry_Click, '');
    try {
      props.connect();
    } catch (error) {
      console.error("Error while connecting:", error);
    }
  };

  const onOpenQR = (): void => {
    reportEvent(OKXReportType.CLICK, Report_Proceed_QRCode_Click, '');
    setShowQR(true);
    props.setLastSelectedWalletInfo({
      ...props.wallet,
      openMethod: "qrcode",
    });
  };

  const onCloseQR = (): void => {
    setShowQR(false);
    props.setLastSelectedWalletInfo({
      ...props.wallet,
      openMethod: "universal-link",
    });
  };

  const onConnectApp = (): void => {
    reportEvent(OKXReportType.CLICK, Report_ChooseType_ConnectWallet_Click, btoa(JSON.stringify({Report_WalletType_Key: 'app'})));
    setQrLink(qrLink()!);
    setWalletType("app");
    onOpenQR();
  };

  const onConnectMini = (): void => {
    // setWalletType("mini");
    logDebug('desktop on connect mini')
    reportEvent(OKXReportType.CLICK, Report_ChooseType_ConnectWallet_Click, btoa(JSON.stringify({Report_WalletType_Key: 'tgminiwallet'})));
    okxConnectUI?.closeModal()
    openOKXTMAWalletlinkWithFallback(miniUniversalLink()!)
  };

  return (
    <MobileConnectionModalStyled data-tc-wallets-modal-connection-mobile="true">
      <Show<boolean, () => JSX.Element>
        when={
          props.universalLinkParams() === null ||
            props.connectError() ||
          showAppAndTgState()
        }
      >
        <H1Styled>OKX Connect</H1Styled>
      </Show>

      <Show<boolean, () => JSX.Element> when={props.universalLinkParams() === null && !props.connectError()}>
        <LoadingModalPage />
        <ModalFooter wallet={props.wallet} />
      </Show>

      <Show<boolean, () => JSX.Element> when={props.universalLinkParams() !== null || props.connectError()}>
        <Show<boolean, () => JSX.Element> when={props.connectError()}>
          <BodyStyled>
            <ConnectionDeclinedModalPage
                onRetry={onRetry}
                showRetry={props.showRetry}
            />
          </BodyStyled>
        </Show>

        <Show<boolean, () => JSX.Element> when={!props.connectError()}>
          <Show<boolean, () => JSX.Element> when={!showAppAndTgState()}>
            <MobileConnectionQR
              universalLink={qrLink()}
              walletInfo={props.wallet}
              sessionConnecting={props.sessionConnecting}
            />
          </Show>

          <Show<boolean, () => JSX.Element> when={showAppAndTgState()}>
            <BodyStyled>
              <ConnectAppTgModalPage
                onConnectApp={onConnectApp}
                onConnectMini={onConnectMini}
              />
            </BodyStyled>
          </Show>
        </Show>

        <ModalFooter wallet={props.wallet} />
      </Show>
    </MobileConnectionModalStyled>
  );
};
