import { createStore } from 'solid-js/store';
import { Locales } from '../../models';

import { OKXUniversalProvider } from '@okxconnect/universal-provider';
import { EngineTypes } from '@okxconnect/core';

export type UniversalAppState = {
    connector: OKXUniversalProvider | null;
    language: Locales;
    connectRequestParameters?: EngineTypes.ConnectParams;
};

export const [universalAppState, setUniversalAppState] = createStore<UniversalAppState>({
    language: 'en_US'
} as UniversalAppState);
