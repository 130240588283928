import type {Component} from 'solid-js';
import {Show} from 'solid-js';
import {Dynamic, Portal} from 'solid-js/web';
import {ThemeProvider} from 'solid-styled-components';
import {AccountButton} from './views/account-button';
import {createI18nContext, I18nContext} from '@solid-primitives/i18n';
import {TonSingleWalletModal} from "./views/modals/wallets-modal/single-ton-wallet-modal";
import {i18nDictionary} from "../app/i18n";
import {
  defineStylesRoot,
  fixMobileSafariActiveTransition,
} from "../app/utils/web-api";
import {
  GlobalStyles,
  globalStylesTag,
} from "../app/styles/global-styles";
import {ActionsModal} from "../app/views/modals";
import {tonAppState} from "./config/ton-app.state";
import {ConnectorContext} from "../config/connector.context";
import {OKXConnectUiContext} from "../config/okx-connect-ui.context";
import { tonAction, setTonAction } from './config/ton-modals-state';
import {OKXConnectUI} from "../okx-connect-ui.interface";
import { tonThemeState } from './theme/ton-theme-state';

export type TonAppProps = {
    connectUI: OKXConnectUI;
};
export const TONApp: Component<TonAppProps> = props => {
    const translations = createI18nContext(i18nDictionary, tonAppState.language);
    defineStylesRoot();
    fixMobileSafariActiveTransition();
    return (
      <I18nContext.Provider value={translations}>
        <OKXConnectUiContext.Provider value={props.connectUI}>
          <ConnectorContext.Provider value={tonAppState.connector!}>
            <GlobalStyles />
            <ThemeProvider theme={tonThemeState}>
              <Show when={tonAppState.buttonRootId}>
                <Portal
                  mount={document.getElementById(tonAppState.buttonRootId!)!}
                >
                  <AccountButton />
                </Portal>
              </Show>
              <Dynamic component={globalStylesTag}>
                <TonSingleWalletModal />
                <ActionsModal action={tonAction()} setAction={setTonAction}/>
              </Dynamic>
            </ThemeProvider>
          </ConnectorContext.Provider>
        </OKXConnectUiContext.Provider>
      </I18nContext.Provider>
    );
};

export default TONApp;