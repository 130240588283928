import { createStore } from 'solid-js/store';
import { Locales } from '../../models';
import { Loadable } from '../../models';

import { OKXITonConnect, ConnectAdditionalRequest } from '@okxconnect/tonsdk';

export type TonAppState = {
    connector: OKXITonConnect | null;
    buttonRootId: string | null;
    language: Locales;
    connectRequestParameters?: Loadable<ConnectAdditionalRequest> | null;
    preferredWalletAppName: string | undefined;
};

export const [tonAppState, setTonAppState] = createStore<TonAppState>({
    buttonRootId: null,
    language: 'en_US'
} as TonAppState);
