import {
  WalletInfo,
  EngineConnectInfo,
  encodeConnectParams,
  encodeTWAConnectURLParams,
  getAppWalletDeepLink,
  getUniversalLink,
  getTelegramWalletTWAUrl,
  openOKXTMAWalletlinkWithFallback,
  openOKXDeeplinkWithFallback,
  logDebug,
  useShortLink,
  reportEvent,
  OKXReportType,
  Report_Proceed_Proceed_View,
  Report_Proceed_QRCode_View,
  Report_Loading_ConnectWallet_View,
  Report_ChooseType_ConnectWallet_View,
  Report_Proceed_Retry_Click,
  Report_Proceed_QRCode_Click,
  Report_ChooseType_ConnectWallet_Click,
  Report_WalletType_Key
} from "@okxconnect/core";
import {Component, createEffect, createMemo, createSignal, JSX, onCleanup, onMount, Show, useContext,} from "solid-js";
import {BodyStyled, MobileConnectionModalStyled, StyledIconButton,} from "./style";
import {useTheme} from "solid-styled-components";
import {MobileConnectionQR} from "./mobile-connection-qr";
import {ConnectorContext} from "../../../../../config/connector.context";
import ConnectAppTgModalPage from "../layout/ConnectAppTgModalPage";
import LoadingModalPage from "../layout/LoadingModalPage";
import ModalFooter from "../layout/ModalFooter";
import { H1Styled } from "../style";
import ConnectionDeclinedModalPage from "../layout/ConnectionDeclinedModalPage";
import ContinueInWalletModalPage from "../layout/ContinueInWalletModalPage";
import {OKXConnectUiContext} from "../../../../../config/okx-connect-ui.context";
import { WalletInfoRemoteWithOpenMethod, WalletInfoWithOpenMethod, WalletOpenMethod } from "../../../../../models";
import {openOKXUniversalLink} from "@okxconnect/core";
import {getOKXLink} from "@okxconnect/core";

export interface MobileConnectionProps {
  wallet: WalletInfo;
  onBackClick: () => void;
  backDisabled?: boolean;
  connect: () => Promise<unknown>;
  isSingleWallet: boolean;
  universalLinkParams: () => EngineConnectInfo | null;
  showRetry:boolean;
  connectError:() => boolean
  sessionConnecting:() => boolean
  lastSelectedWalletInfo: WalletInfoRemoteWithOpenMethod | {
    openMethod: WalletOpenMethod;
  } | null;
  setLastSelectedWalletInfo(walletInfo: WalletInfoWithOpenMethod | {
    openMethod: WalletOpenMethod;
} | null): void;
}

export const MobileConnectionModal: Component<MobileConnectionProps> = (
  props
) => {
  const theme = useTheme();
  const [firstClick, setFirstClick] = createSignal(true);
  const [showQR, setShowQR] = createSignal(false);
  const [connectClicked, setConnectClicked] = createSignal(false);
  const showAppAndTgState = createMemo(
    () => !props.isSingleWallet && !connectClicked()
  );

  const [appDeepLink, setAppDeepLink] = createSignal<string | null>(null);
  const [miniUniversalLink, setMiniUniversalLink] = createSignal<string | null>(
    null
  );
  const [qrLink, setQrLink] = createSignal<string | null>(null);
  const okxConnectUI = useContext(OKXConnectUiContext)
  var lastActionBtnShowState: boolean | undefined;
  var lastQRViewShowState: boolean | undefined;
  let showLoading = () => props.universalLinkParams() === null && !props.connectError();
  var lastLoadingState: boolean | undefined;

  createEffect(() => {
    var info = props.universalLinkParams();
    const showState = showAppAndTgState();
    const showQRState = showQR();
    const showLoadingState = showLoading();
    if (showState != lastActionBtnShowState && !showQRState && !showLoadingState) {
      if (showState) {
        // report show
        reportEvent(OKXReportType.PAGE_IN, Report_Proceed_Proceed_View, '')
        reportEvent(OKXReportType.PAGE_OUT, Report_ChooseType_ConnectWallet_View, '')
      } else {
        // report hidden
        reportEvent(OKXReportType.PAGE_OUT, Report_Proceed_Proceed_View, '')
        reportEvent(OKXReportType.PAGE_IN, Report_ChooseType_ConnectWallet_View, '')
      }
      lastActionBtnShowState = showState;
    }

    if (showQRState != lastQRViewShowState && !showLoadingState) {
      if (showQRState) {
        // report show
        reportEvent(OKXReportType.PAGE_IN, Report_Proceed_QRCode_View, '')
      } else {
        // report hidden
        reportEvent(OKXReportType.PAGE_OUT, Report_Proceed_QRCode_View, '')
      }
      lastQRViewShowState = showQRState;
    }

    if (showLoadingState != lastLoadingState) {
      if (showLoadingState) {
        // report show
        reportEvent(OKXReportType.PAGE_IN, Report_Loading_ConnectWallet_View, '')
      } else {
        // report hidden
        reportEvent(OKXReportType.PAGE_OUT, Report_Loading_ConnectWallet_View, '')
      }
      lastLoadingState = showLoadingState;
    }

    if (info) {
      props.setLastSelectedWalletInfo({
        ...props.wallet,
        openMethod: "universal-link",
      });

      logDebug(`createEffect  ${JSON.stringify(info)}`)
      var connectRequest = info.connectRequest
      var tonConnectRequest = info.tonConnectRequest
      if (!tonConnectRequest){
        tonConnectRequest = connectRequest
      }

      var qrLink = ""
      var appDeepLink = ""
      if (connectRequest) {
        if (useShortLink()) {
          var shortUniversalLink =  getOKXLink(connectRequest)
          setQrLink(shortUniversalLink);
          setAppDeepLink(shortUniversalLink)
        } else {
          var encodeParams = encodeConnectParams({
            ...tonConnectRequest,
            redirect: "none"
          })
          qrLink = getUniversalLink(getAppWalletDeepLink(encodeParams), true)
          setQrLink(qrLink);

          var appConnectEncodeParams = encodeConnectParams({
            ...tonConnectRequest,
            redirect: info.redirect ?? "none"
          })
          appDeepLink = getAppWalletDeepLink(appConnectEncodeParams)
          setAppDeepLink(appDeepLink)
        }

        var encodeTGParams = encodeTWAConnectURLParams({
          ...connectRequest,
          redirect: "none"
        })

        var tgUrl = getTelegramWalletTWAUrl(encodeTGParams, info.tmaReturnUrl ?? "back")
        setMiniUniversalLink(tgUrl)
        logDebug(`setUniversalLink  ${appDeepLink}   tgUrl  ${tgUrl}    qrLink ${qrLink}`)
      }
    }

    if (props.connectError()){
      setShowQR(false)
    }
  });

  onMount(async () => {
    try {
      await props.connect().catch((error) => 
        logDebug(`MobileConnectionModal onMount error ${JSON.stringify(error)}`)
      )
    } catch (error) {
      console.error("Error while connecting:", error);
    }
  });

  const onRetry = (): void => {
    setConnectClicked(false);
    const forceRedirect = !firstClick();
    setFirstClick(false);
    reportEvent(OKXReportType.CLICK, Report_Proceed_Retry_Click, '');

    try {
      props.connect();
    } catch (error) {
      console.error("Error while connecting:", error);
    }
  };

  const onOpenQR = (): void => {
    reportEvent(OKXReportType.CLICK, Report_Proceed_QRCode_Click, '');
    setShowQR(true);
    props.setLastSelectedWalletInfo({
      ...props.wallet,
      openMethod: "qrcode",
    });
  };

  const onCloseQR = (): void => {
    setShowQR(false);
    props.setLastSelectedWalletInfo({
      ...props.wallet,
      openMethod: "universal-link",
    });
  };

  const onBack = (): void => {
    if (showQR()) {
      onCloseQR();
    } else {
      props.onBackClick();
    }
  };

  const onConnectApp = (): void => {
    reportEvent(OKXReportType.CLICK, Report_ChooseType_ConnectWallet_Click, JSON.stringify({Report_WalletType_Key: 'app'}));
    setConnectClicked(true);
    if (useShortLink()){
      openOKXUniversalLink(appDeepLink()!)
    }else{
      openOKXDeeplinkWithFallback(appDeepLink()!)
    }
  };

  const onConnectMini = (): void => {
    reportEvent(OKXReportType.CLICK, Report_ChooseType_ConnectWallet_Click, JSON.stringify({Report_WalletType_Key: 'tgminiwallet'}));
    okxConnectUI?.closeModal()
    openOKXTMAWalletlinkWithFallback(miniUniversalLink()!)
  };

  // {/*<MobileConnectionQR universalLink={universalLink()} walletInfo={props.wallet} />*/}

  return (
    <MobileConnectionModalStyled data-tc-wallets-modal-connection-mobile="true">
      <Show<boolean, () => JSX.Element> when={!props.backDisabled || showQR()}>
        <StyledIconButton icon="arrow" onClick={onBack} />
      </Show>

      <Show<boolean, () => JSX.Element> when={props.universalLinkParams() === null && !props.connectError()}>
        <H1Styled>OKX Connect</H1Styled>
        <LoadingModalPage />
        <ModalFooter wallet={props.wallet} />
      </Show>


      <Show<boolean, () => JSX.Element> when={props.universalLinkParams() !== null || props.connectError()}>
        <Show<boolean, () => JSX.Element> when={showQR() && !props.connectError()}>
            <MobileConnectionQR
                universalLink={qrLink()}
                walletInfo={props.wallet}
                sessionConnecting = {props.sessionConnecting}
            />
        </Show>

        <Show<boolean, () => JSX.Element> when={!showQR()}>
          <H1Styled>OKX Connect</H1Styled>
          <BodyStyled isDeclined={props.connectError()}>
            <Show<boolean, () => JSX.Element> when={props.connectError()}>
              <ConnectionDeclinedModalPage
                  onRetry={onRetry}
                  showRetry = {props.showRetry}
              />
            </Show>

            <Show<boolean, () => JSX.Element> when={!props.connectError()}>
              <Show<boolean, () => JSX.Element> when={showAppAndTgState()}>
                <ConnectAppTgModalPage
                  onConnectApp={onConnectApp}
                  onConnectMini={onConnectMini}
                />
              </Show>

              <Show<boolean, () => JSX.Element> when={!showAppAndTgState()}>
                <ContinueInWalletModalPage
                  onOpenQR={onOpenQR}
                  onRetry={onRetry}
                />
              </Show>
            </Show>
          </BodyStyled>

          <ModalFooter wallet={props.wallet} />
        </Show>
      </Show>
    </MobileConnectionModalStyled>
  );
};
