import { createMemo, createSignal } from 'solid-js';
import { WalletInfoWithOpenMethod, WalletOpenMethod } from '../../models';
import { LastSelectedWalletInfoStorage } from '../../storage';
import { WalletsModalState } from '../../models';
import { SingleWalletModalState } from '../../models/single-wallet-modal';
import {logDebug} from "../../app/utils/log";
import { Action } from '../../app/models/action-modal';

export const [tonWalletsModalState, setTonWalletsModalState] = createSignal<WalletsModalState>({
    status: 'closed',
    closeReason: null
});

export const getTonWalletsModalIsOpened = createMemo(() => tonWalletsModalState().status === 'opened');

export const [tonSingleWalletModalState, setTonSingleWalletModalState] =
    createSignal<SingleWalletModalState>({
        status: 'closed',
        closeReason: null
    });

export const getTonSingleWalletModalIsOpened = createMemo(
    () => tonSingleWalletModalState().status === 'opened'
);

export const getTonSingleWalletModalWalletInfo = createMemo(() => {
    const state = tonSingleWalletModalState();
    if (state.status === 'opened') {
        return state.walletInfo;
    }

    return null;
});

let lastSelectedWalletInfoStorage =
    typeof window !== 'undefined' ? new LastSelectedWalletInfoStorage() : undefined;
export const [lastTonSelectedWalletInfo, _setLastTonSelectedWalletInfo] = createSignal<
    | WalletInfoWithOpenMethod
    | {
          openMethod: WalletOpenMethod;
      }
    | null
>(lastSelectedWalletInfoStorage?.getLastSelectedWalletInfo() || null);

export const setLastTonSelectedWalletInfo = (
    walletInfo:
        | WalletInfoWithOpenMethod
        | {
              openMethod: WalletOpenMethod;
          }
        | null
): void => {
    if (!lastSelectedWalletInfoStorage) {
        lastSelectedWalletInfoStorage = new LastSelectedWalletInfoStorage();
    }

    if (walletInfo) {
        logDebug(`modals-state.ts: setLastSelectedWalletInfo ${JSON.stringify(walletInfo)}`)
        lastSelectedWalletInfoStorage.setLastSelectedWalletInfo(walletInfo);
    } else {
        logDebug("modals-state.ts: setLastSelectedWalletInfo    removeLastSelectedWalletInfo")
        lastSelectedWalletInfoStorage.removeLastSelectedWalletInfo();
    }
    _setLastTonSelectedWalletInfo(walletInfo);
};

export const [tonAction, setTonAction] = createSignal<Action | null>(null);
