import {logDebug as logDebugCore,logError as logErrorCore,logWarning as LogWarningCore} from '@okxconnect/core'
export function logDebug(...args: Parameters<Console['debug']>): void {
    logDebugCore(args)
}

export function logError(...args: Parameters<Console['error']>): void {
    logErrorCore(args)
}

export function logWarning(...args: Parameters<Console['warn']>): void {
    LogWarningCore(args)
}
