import { Component, Show } from "solid-js";
import { H2Styled, LoaderWrapper, QrCodeWrapper } from "./style";
import { QRCode } from "../../../../../components";
import { WalletInfo } from "@okxconnect/core";

import { addReturnStrategy } from "../../../../../utils/url-strategy-helpers";
import imageUrl from "../../../../../assets/ok_icon.png";
import { OKXLoaderIconWithLogo } from "../../../../../components/icons/okx-loader-icon";
import { H1Styled } from "../../style";

interface MobileConnectionQRProps {
  universalLink: string | null;
  walletInfo: Pick<WalletInfo, "name" | "imageUrl">;
  sessionConnecting:() => boolean
}

export const MobileConnectionQR: Component<MobileConnectionQRProps> = (
  props
) => {
  return (
    <>
      <H1Styled translationKey="walletModal.mobileConnectionModal.OKXConnect" />
      <QrCodeWrapper>
        <Show when={props.sessionConnecting()}>
          <LoaderWrapper>
            <OKXLoaderIconWithLogo />
          </LoaderWrapper>
        </Show>
        <Show when={props.universalLink !== null && !props.sessionConnecting()}>
          <QRCode
            imageUrl={imageUrl}
            sourceUrl={addReturnStrategy(props.universalLink!, "none")}
            disableCopy
          />
        </Show>
      </QrCodeWrapper>
      <H2Styled
        translationKey="walletModal.mobileConnectionModal.scanQR"
        translationValues={{ name: props.walletInfo.name }}
      >
        Scan the QR code below with your phone’s or {props.walletInfo.name}’s
        camera
      </H2Styled>
    </>
  );
};
