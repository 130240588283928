import {Component, createSignal, useContext} from "solid-js";
import {ActionConfiguration, WalletsModalCloseReason} from "../../../models";
import {universalAppState} from "../../config/universal-app.state";
import {UniversalConnectorContext} from "../../../config/connector.context";
import {OKXConnectUiContext} from "../../../config/okx-connect-ui.context";
import {SingleWalletModal} from "../../../app/views/modals/wallets-modal/single-wallet-modal";
import {EngineConnectInfo, EngineTypes, logDebug, SessionTypes} from "@okxconnect/core";
import {OKXUniversalConnectUI} from "../../okx-connect-universal-ui";
import {OKXConnectUiError} from "../../../errors";
import {
    defaultOpenUniversalLink,
    showUniversalTgWalletByRequestNameSpaces,
} from "../../../app/utils/wallets";
import { 
    getUniversalSingleWalletModalIsOpened,
    getUniversalSingleWalletModalWalletInfo,
    lastUniversalSelectedWalletInfo, 
    setLastUniversalSelectedWalletInfo,
} from "../../config/universal-modals-state";


export const UniversalSingleWalletModal: Component = () => {
    const universalConnectUI = useContext(
        OKXConnectUiContext
    )! as OKXUniversalConnectUI;
    const connector = useContext(UniversalConnectorContext)!;
    const [universalLinkParams, setUniversalLinkParams] = createSignal<EngineConnectInfo | null>(null);
    const [connectError, setConnectError] = createSignal<boolean>(false);
    const [sessionConnecting, setSessionConnecting] = createSignal<boolean>(false);

    const onClose = (closeReason: WalletsModalCloseReason): void => {
        universalConnectUI.closeModal(closeReason);
    };

    connector?.on("okx_engine_connect_params", (info: EngineConnectInfo) => {
        logDebug(`UniversalSigneWalletModal  okx_engine_connect_params  ${JSON.stringify(info)}`)
        setUniversalLinkParams(info)
    });

    connector?.on("session_connecting", () => {
        logDebug(`UniversalSigneWalletModal  session_connecting  }`)
        setSessionConnecting(true)
    });

    return (
        <SingleWalletModal
            opened={getUniversalSingleWalletModalIsOpened()}
            walletInfo={getUniversalSingleWalletModalWalletInfo()}
            loadingBeforeConnect={() => {
                return false;
            }}
            connect={() => {
                return new Promise<SessionTypes.Struct>((resolve, reject) => {
                    if (universalAppState.connectRequestParameters) {
                        new OKXConnectUiError("connect params is nil");
                    }
                    let opts:EngineTypes.ConnectParams = {
                        ...JSON.parse(
                            JSON.stringify(universalAppState.connectRequestParameters)
                        ),
                    };
                    opts.sessionConfig = {}
                    opts.sessionConfig.openUniversalUrl =
                        defaultOpenUniversalLink(universalAppState.connectRequestParameters?.namespaces,
                            universalAppState.connectRequestParameters?.optionalNamespaces)

                    setConnectError(false)
                    setUniversalLinkParams(null)
                    setSessionConnecting(false)
                    universalConnectUI.handleConnect((actionConfiguration:ActionConfiguration | undefined) => {
                        opts.sessionConfig!.tmaReturnUrl = actionConfiguration?.tmaReturnUrl
                        opts.sessionConfig!.redirect = actionConfiguration?.returnStrategy
                        return connector.connect(opts)
                    }).then((session) => {
                        if (session) {
                            onClose("wallet-selected");
                            resolve(session)
                        }else {
                            setConnectError(true)
                            onClose("action-cancelled");
                        }
                    }).catch((error) => {
                        // onClose("action-cancelled");
                        setConnectError(true)
                        logDebug("UniversalSingleWalletModal connect  error", JSON.stringify(error))
                        reject(error);
                    });

                });
            }}
            onClose={onClose}
            universalLinkParams={universalLinkParams}
            isSingleWallet={!showUniversalTgWalletByRequestNameSpaces(universalAppState.connectRequestParameters?.namespaces,
                universalAppState.connectRequestParameters?.optionalNamespaces)}
            lastSelectedWalletInfo={lastUniversalSelectedWalletInfo()}
            setLastSelectedWalletInfo={setLastUniversalSelectedWalletInfo}
            connectError ={connectError}
            showRetry ={false}
            language={universalAppState.language}
            sessionConnecting = {sessionConnecting}
        />
    );
};
