
import {createEffect} from 'solid-js';
import {logDebug, WalletInfo} from "@okxconnect/core";
import {tonWidgetController as widgetController} from '../config/ton-widget-controller';
import {SingleWalletModalState} from '../../models/single-wallet-modal';
import {isInTMA, sendExpand} from '../../app/utils/tma-api';
import {OKXConnectUiError} from '../../errors';
import {eqWalletName} from '../../app/utils/wallets';
import {ActionConfiguration, WalletsModalCloseReason} from "../../models";
import {tonSingleWalletModalState} from "../config/ton-modals-state";

interface SingleWalletModalManagerCreateOptions {
    getWalltsInfo: () => WalletInfo[]
}

/**
 * Manages the modal window state.
 */
export class TonSingleWalletModalManager {

    private consumers: Array<(state: SingleWalletModalState) => void> = [];
    private getWalltsInfo: () => WalletInfo[]

    public state: SingleWalletModalState = tonSingleWalletModalState();

    constructor(options: SingleWalletModalManagerCreateOptions) {
        this.getWalltsInfo = options.getWalltsInfo
        createEffect(() => {
            const state = tonSingleWalletModalState();
            this.state = state;
            this.consumers.forEach(consumer => consumer(state));
        });
    }

    /**
     * Opens the modal window with the specified wallet.
     * @param wallet - Wallet app name.
     * @throws OKXConnectUiError if the specified wallet is not found.
     */
    public async open(wallet: string): Promise<void> {
        const fetchedWalletsList = this.getWalltsInfo();
        const walletsList = fetchedWalletsList;

        const externalWallets = walletsList
        const externalWallet = externalWallets.find(walletInfo => eqWalletName(walletInfo, wallet));
        if (externalWallet) {
            return this.openSingleWalletModal(externalWallet);
        }

        const error = `Trying to open modal window with unknown wallet "${wallet}".`;
        throw new OKXConnectUiError(error);
    }

    /**
     * Closes the modal window.
     * @default 'action-cancelled'
     */
    public close(reason:WalletsModalCloseReason = 'action-cancelled'): void {
        widgetController.closeSingleWalletModal(reason);
    }

    /**
     * Subscribe to the modal window state changes, returns unsubscribe function.
     */
    public onStateChange(onChange: (state: SingleWalletModalState) => void): () => void {
        this.consumers.push(onChange);

        return () => {
            this.consumers = this.consumers.filter(consumer => consumer !== onChange);
        };
    }

    public async handleConnect(
        connectMethod: (actionConfiguration: ActionConfiguration | undefined) => Promise<string>,
        actionConfiguration: ActionConfiguration | undefined
    ): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            try {
                connectMethod(actionConfiguration).then((result) => {
                    resolve(result);
                    logDebug("TonSingleWalletModalManager  result >>>",JSON.stringify(result));

                }).catch((error) => {
                    reject(error);
                    logDebug("TonSingleWalletModalManager  error >>>",JSON.stringify(error));
                });
            } catch (error) {
                reject(error);
                logDebug("TonSingleWalletModalManager  catch error >>>",JSON.stringify(error));
            }
        });
    };


    /**
     * Opens the modal window to connect to a specified wallet, and waits when modal window is opened.
     */
    public async openSingleWalletModal(wallet: WalletInfo): Promise<void> {
        if (isInTMA()) {
            sendExpand();
        }

        widgetController.openSingleWalletModal(wallet);

        return new Promise<void>(resolve => {
            const unsubscribe = this.onStateChange(state => {
                const {status} = state;
                if (status === 'opened') {
                    unsubscribe();
                    resolve();
                }
            });
        });
    }
}
