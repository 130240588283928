import {Component, createEffect, createSignal, JSXElement, Show, useContext} from 'solid-js';

import {ActionModalStyled, ButtonStyled, H1Styled, TextStyled} from './style';
import {WithDataAttributes} from '../../../../models/with-data-attributes';
import {useDataAttributes} from '../../../../hooks/use-data-attributes';
import {redirectToWallet} from '../../../../utils/url-strategy-helpers';
import {Translation} from '../../../../components/typography/Translation';
import {logDebug, ReturnStrategy} from "@okxconnect/core";
import {Text} from '../../../../components';
import {useTheme} from "solid-styled-components";
import {OKXConnectUiContext} from "../../../../../config/okx-connect-ui.context";
import {ActionsModalContext} from '../actions-modal';

interface ActionModalProps extends WithDataAttributes {
    headerTranslationKey: string;
    headerTranslationValues?: Record<string, string>;
    icon: JSXElement;
    textTranslationKey?: string;
    textTranslationValues?: Record<string, string>;
    onClose: () => void;
    showButton?: 'close' | 'open-wallet';
    returnStrategy?: ReturnStrategy;
}

export const ActionModal: Component<ActionModalProps> = props => {
    const dataAttrs = useDataAttributes(props);
    const connectUI = useContext(OKXConnectUiContext);
    const [firstClick, setFirstClick] = createSignal(true);
    const [sent, setSent] = createSignal(false);
    const theme = useTheme()
    const { action } = useContext(ActionsModalContext);

    createEffect(() => {
        const currentAction = action;
        setSent(!!currentAction && 'sent' in currentAction && currentAction.sent);
    });

    let universalLink: string | undefined = connectUI?.universalLink;
    let deepLink: string | undefined;


    const onOpenWallet = (): void => {
        logDebug("ActionModal ====> onOpenWallet ")
        // const currentAction = action;
        // const returnStrategy =
        //     currentAction && 'returnStrategy' in currentAction
        //         ? currentAction.returnStrategy
        //         : props.returnStrategy;

        const forceRedirect = !firstClick();
        setFirstClick(false);
            redirectToWallet(
                universalLink!,
                'okx://web3?stack=true',
                {
                    returnStrategy: "none",
                    forceRedirect: false
                },
                () => {
                }
            );
        // }
    };

    return (
        <ActionModalStyled {...dataAttrs}>
            {props.icon}
            <H1Styled
                translationKey={props.headerTranslationKey}
                translationValues={props.headerTranslationValues}
            />
            <TextStyled
                translationKey={props.textTranslationKey}
                translationValues={props.textTranslationValues}
            />
            {/*<Show*/}
            {/*    when={*/}
            {/*        !sent() && !props.icon &&*/}
            {/*        ((props.showButton === 'open-wallet' && universalLink) ||*/}
            {/*            props.showButton !== 'open-wallet')*/}
            {/*    }*/}
            {/*>*/}
            {/*    <LoaderButtonStyled disabled={true} data-tc-connect-button-loading="true">*/}
            {/*        <LoaderIconStyled/>*/}
            {/*    </LoaderButtonStyled>*/}

            {/*</Show>*/}
            <Show when={connectUI?.showActionButton()}>
                <Show when={props.showButton === 'close'}>
                    <ButtonStyled onClick={() => props.onClose()}>
                        <Text fontWeight = {700} fontSize = {"16px"} color = {theme.colors.background.primary} >
                            <Translation translationKey="common.close">Close</Translation>
                        </Text>

                    </ButtonStyled>
                </Show>
                <Show when={props.showButton === 'open-wallet'}>
                    <ButtonStyled onClick={onOpenWallet}>
                        <Text fontWeight = {700} fontSize = {"16px"} color = {theme.colors.background.primary} >
                            <Translation translationKey="common.openWallet">Open wallet</Translation>
                        </Text>

                    </ButtonStyled>
                </Show>
            </Show>
        </ActionModalStyled>
    );
};
